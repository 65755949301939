import React from 'react'
import Layout from '../components/Layout'
import Page from '../components/Page'
import Container from '../components/shared/Container'

const NotFoundPage = () => (
  <Layout>
    <Page>
      <Container>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Container>
    </Page>
  </Layout>
)

export default NotFoundPage
